.user-tos-modal {
  &__container {
    @apply grid p-container gap-y-7 text-center;
    grid-template-areas:
      'logo'
      'message'
      'action'
      'reject';

    @screen md {
      @apply gap-y-10;
    }
  }

  &__message-container {
    @apply flex flex-col gap-y-2 ;
    grid-area: message;

    @screen md {
      @apply gap-y-3;
    }
  }

  &__message-title {
    @apply font-semibold text-7xl leading-tighter;
  }

  &__message-content {
    @apply text-2xl leading-tight;
  }

  &__logo {
    @apply h-14 w-14 justify-self-center;
    grid-area: logo;
  }

  &__button {
    @apply uppercase border border-brand-500 py-2 px-12 text-2xl leading-tight
      font-bold transition text-center m-auto bg-brand-500 text-black text-2xl;
    grid-area: action;

    &:hover, &:focus {
      @apply text-base-100;
    }
  }

  &__highlighted-text {
    @apply text-2xl font-bold leading-tight text-brand-500 normal-case cursor-pointer
    transition;

    &:hover, &:focus {
      @apply text-base-100;
    }
  }
}
