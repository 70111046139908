.app-menu {
  &__navbar {
    @apply sticky top-0 inset-x-0 border-b border-b-base-300 bg-base-800 z-app-menu h-18;
  }

  &__container {
    @apply container mx-auto grid h-full items-center gap-8;
    grid-template-areas: "logo-image right-actions";
    grid-template-columns: 1fr auto;

    @screen lg {
      grid-template-areas: "logo-image center-actions right-actions";
      grid-template-columns: 1fr auto 1fr;
    }
  }

  &__logo-image {
    @apply mb-2 w-32 mr-auto mt-auto transition-opacity;
    grid-area: logo-image;

    &:hover {
      @apply opacity-75;
    }
  }

  &__center-actions {
    @apply hidden;
    grid-area: center-actions;

    @screen lg {
      @apply flex items-center gap-12;
    }
  }

  &__center-actions-link {
    @apply py-2 px-0 font-bold text-2xl leading-none uppercase;

    &_active {
      @apply text-brand-500 border-b border-brand-500 -mb-px;

      &:hover, &focus {
        @apply text-base-100;
      }
    }
  }

  &__right-actions {
    @apply flex items-center gap-8 justify-self-end relative;
    grid-area: right-actions;
  }

  &__user-avatar {
    @apply hidden;

    @screen lg {
      @apply block w-14 h-14 rounded-full overflow-hidden border border-base-100 bg-base-100 cursor-pointer;
    }
  }

  &__user-avatar-default {
    @apply w-full h-full;
  }

  &__connect-wallet-button {
    button {
      @apply text-lg truncate cursor-pointer font-bold font-sans uppercase rounded-full border transition-all border-white lg:w-auto;
      min-width: 120px!important;

      &:hover, &:focus {
        @apply rounded-full;
      }

      svg:first-child, svg:nth-child(3) {
        @apply hidden;
      }

      @screen lg {
        @apply text-xl px-5 py-2.5;

        svg:first-child, svg:nth-child(3) {
          @apply block;
        }
      }
    }
  }

  &__login-button {
    @apply hidden sm:block inline-block pt-3.5 px-3 pb-3 border-solid border border-brand-500 text-xl text-brand-500 uppercase leading-none
      font-bold bg-no-repeat bg-gradient-to-r from-brand-800 to-base-800 transition-[background-position];
    background-position: -1000px;

    &:not(&:disabled):hover,
    &:not(&:disabled):focus {
      background-position: 0;
    }
  }

  &__open-mobile-menu-button {
    @apply text-5xl;

    @screen lg {
      @apply hidden;
    }
  }
}
