.app-footer {
  &__container {
    @apply w-full bg-base-550;
  }

  &__content-container {
    @apply container mx-auto grid place-items-center gap-y-4 py-8 text-base-300;
    grid-template-areas:
      "social-networks  social-networks"
      "platform         contact"
      "copyright        copyright";

    @screen md {
      @apply py-4;

      grid-template-areas:
        "social-networks  social-networks social-networks"
        "platform         copyright       contact";
      grid-template-columns: 1fr auto 1fr;
    }
  }

  &__platform {
    @apply flex flex-col text-brand-500 font-bold text-2xl;
    grid-area: platform;

    &__item {
      @apply px-2;

      &:hover, &:focus {
        @apply text-base-100;
      }

      @screen md {
        @apply text-base-100 normal-case justify-self-end;

        &:hover, &:focus {
          @apply text-brand-500;
        }
      }
    }
  }

  &__copyright {
    @apply text-xl font-medium;
    grid-area: copyright;

    @screen md {
      @apply justify-self-start font-bold text-base-100 leading-none;
    }
  }

  &__social-networks-list {
    @apply flex gap-x-8;
    grid-area: social-networks;
  }

  &__social-network-link {
    @apply text-base-300 text-2xl;

    @screen md {
      @apply text-4xl leading-none;
    }
  }

  &__contact {
    @apply text-brand-500 font-bold text-2xl uppercase flex flex-col;
    grid-area: contact;

    &__item {
      @apply px-2;

      &:hover, &:focus {
        @apply text-base-100;
      }

      @screen md {
        @apply text-base-100 normal-case justify-self-end;

        &:hover, &:focus {
          @apply text-brand-500;
        }
      }
    }
  }

  &__contact-icon-link {
    @apply text-4xl ml-2;

    @screen md {
      @apply hidden;
    }
  }
}
