body {
  @apply bg-base-800 text-base text-white overflow-y-scroll overflow-x-hidden;
}

a {
  @apply transition;

  &:hover, &:focus {
    @apply text-brand-500;
  }
}
