/* purgecss start ignore */
@tailwind base;
/* ▼ BASE ▼ */
@import "~/styles/reset";
@import "~/styles/web3Modal";
@import "~/styles/fonts/space-grotesk";
/* ▲ BASE ▲ */

@tailwind components;
/* ▼ COMPONENTS ▼ */
/* ▲ COMPONENTS ▲ */

/* purgecss end ignore */

@tailwind utilities;
/* ▼ UTILITIES ▼ */
/* ▲ UTILITIES ▲ */
