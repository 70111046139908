.mobile-menu {
  &__backdrop {
    @apply fixed inset-0 bg-transparent hidden;

    &-enter {
      @apply block;
    }

    &-enter-active {
      @apply block bg-base-800/70 transition-colors;
    }

    &-enter-done {
      @apply block bg-base-800/70;
    }

    &-exit {
      @apply block bg-base-800/70;
    }

    &-exit-active {
      @apply block bg-transparent transition-colors;
    }

    &-exit-done {
      @apply hidden;
    }

    @screen lg {
      // NOTE: This entire component is mobile-exclusive, see DesktopMenu component for desktop version
      @apply hidden;
    }
  }

  &__slide-out {
    @apply grid fixed inset-y-0 right-0 border-l border-base-300 bg-base-800 leading-tight translate-x-full;
    width: calc(100vw - theme('spacing.24'));
    grid-template-areas:
      "slide-out-header"
      "top-navigation"
      "bottom-navigation";
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;

    &-enter-active {
      @apply translate-x-0 transition-transform;
    }

    &-enter-done {
      @apply translate-x-0;
    }

    &-exit {
      @apply translate-x-0;
    }

    &-exit-active {
      @apply translate-x-full transition-transform;
    }
  }

  &__slide-out-header {
    @apply flex items-center justify-end border-b border-base-550 h-16 px-container;
    grid-area: slide-out-header;

    @screen sm {
      @apply px-container-sm;
    }
  }

  &__close-menu-button {
    @apply text-5xl;
  }

  &__top-navigation {
    grid-area: top-navigation;
  }

  &__top-navigation-link {
    @apply block w-full py-6 px-container border-b border-base-550 transition-colors
      leading-none text-left font-bold text-2xl uppercase truncate;

    @screen sm {
      @apply px-container-sm;
    }

    &_active {
      @apply text-brand-500;

      &:hover, &focus {
        @apply text-base-100;
      }
    }
  }

  &__bottom-navigation {
    grid-area: bottom-navigation;
  }

  &__login-button {
    @apply block py-6 px-container w-full border-solid border border-brand-500 -ml-px
      text-brand-500  leading-none text-left font-bold text-2xl uppercase truncate
      bg-no-repeat bg-gradient-to-r from-brand-800 to-base-800 transition-[background-position];
    background-position: -1000px;

    @screen sm {
      @apply px-container-sm;
    }

    &:not(&:disabled):hover,
    &:not(&:disabled):focus {
      background-position: 0;
    }
  }

  &__bottom-navigation-link {
    @apply block w-full py-6 px-container border-t border-base-550 transition-colors
      leading-none text-left font-bold text-2xl uppercase truncate;

    @screen sm {
      @apply px-container-sm;
    }

    &:hover, &focus {
      @apply text-brand-500;
    }
  }
}
