.desktop-menu {
  &__dropdown {
    // NOTE: This entire component is desktop-exclusive, see MobileMenu component for mobile version
    @apply hidden;

    @screen lg {
      &_open {
        @apply absolute bottom-0 right-0 block py-1 bg-base-600 border border-base-300;
        transform: translateY(calc(100% + theme('spacing.4') - 1px));
      }
    }
  }

  &__dropdown-button {
    @apply block py-3 px-5 text-base text-left text-base-100 leading-tighter font-bold transition-colors
      whitespace-nowrap;

    &:hover, &focus {
      @apply text-brand-500;
    }
  }
}
