.modal {
  &__container {
    @apply max-w-xs w-full h-max border border-base-300 bg-base-800 hidden opacity-0 scale-50;

    &-enter {
      @apply block;
    }

    &-enter-active {
      @apply block opacity-100 scale-100 transition-all;
    }

    &-enter-done {
      @apply block opacity-100 scale-100;
    }

    &-exit {
      @apply block opacity-100 scale-100;
    }

    &-exit-active {
      @apply block scale-50 opacity-0 transition-all;
    }

    &-exit-done {
      @apply hidden;
    }

    @screen md {
      @apply max-w-md;
    }

    @screen lg {
      @apply max-w-lg;
    }
  }

  &__modal-backdrop {
    @apply hidden place-items-center fixed inset-0 bg-transparent z-backdrop;

    &-enter {
      @apply grid;
    }

    &-enter-active {
      @apply grid bg-base-800/70 transition-colors;
    }

    &-enter-done {
      @apply grid bg-base-800/70;
    }

    &-exit {
      @apply grid bg-base-800/70;
    }

    &-exit-active {
      @apply grid bg-transparent transition-colors;
    }

    &-exit-done {
      @apply hidden;
    }

    @screen md {
      @apply p-16;
    }

    @screen lg {
      @apply p-24;
    }
  }

  &__close-modal-button {
    @apply absolute top-0 right-0 -mt-8 hidden;

    @screen md {
      @apply block text-2xl;
    }

    &:hover, :focus {
      @apply cursor-pointer;
    }
  }

  &__link {
    @apply text-base-300;

    &:hover, &:focus {
      @apply text-brand-500;
    }
  }
}
